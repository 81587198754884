let projects = [
    {
        id: 1,
        name: "Collaborative Document Editor",
        description: 
            "A real-time collaborative document editing platform inspired by Google Docs. This application enables users to create, share, and collaborate on documents simultaneously. It features real-time updates, user authentication, and a rich text editing experience.",
        src: "collab-docs.png",
        tech: "React.js, Express.js, Socket.io, MongoDB, Quill, Material UI",
        link: "https://collaborative-doc-editor-opal.vercel.app",
        git: "https://github.com/TechAlhan826/Collaborative-Docs-Editor",
    },
    {
        id: 2,
        name: "Share Files",
        description:
            "A robust file sharing solution that allows users to upload files, generate shareable links, and send links via email. The platform prioritizes security with password-protected file access. It offers a seamless user experience with a modern, responsive design.",
        src: "share-files.png",
        tech: "Next.js, React, Tailwind CSS, Supabase, MongoDB",
        link: "https://auth-profile.vercel.app",
        git: "https://github.com/TechAlhan826/Share-Files-A-File-Sharing-Platform",
    },
    {
        id: 3,
        name: "The Multi Pay - App",
        description:
            "A comprehensive utility bill payment mobile application, available on the Play Store. The app facilitates various services including mobile recharges and DTH payments. It offers a user-friendly interface and secure transaction processing, catering to everyday payment needs.",
        src: "the-multi-pay-app.png",
        tech: "Flutter, Firebase",
        link: "https://play.google.com/store/apps/dev?id=4986845651597504207",
        
    },
    {
        id: 4,
        name: "The Multi Pay - Web",
        description:
            "The web version of the Multi Pay platform, offering utility bill payment services such as mobile and DTH recharges. This responsive web application provides a seamless experience across devices, ensuring accessibility for all users. It features secure payment processing and user account management.",
        src: "the-multi-pay-web.png",
        tech: "HTML, Tailwind CSS, PHP, Laravel, MySQL",
        link: "https://recharge.tndigitalservices.co.in",
      
    },
    {
        id: 5,
        name: "TN PAN Services",
        description:
            "An authorized pan-India services portal for NSDL & UTI (Income Tax). The platform offers essential services like new eKYC PAN applications, PAN corrections, and lost PAN reissues. It implements a multi-tier user role system, supporting a network of retailers and distributors for widespread service delivery.",
        src: "tn-pan.png",
        tech: "HTML, Bootstrap, Tailwind CSS, PHP, MySQL",
        link: "http://tndigitalservices.co.in",
       
    },
	{
		id: 6,
		name: "View all on my GitHub",

		src: "github.svg",
		link: "https://github.com/TechAlhan826",
		git: "https://github.com/TechAlhan826",
	},
];

export default projects;
