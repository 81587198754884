/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { Grid } from "@material-ui/core";
import Fade from "react-reveal";
import { motion } from "framer-motion";
import "./About.css";
import { GetApp } from "@material-ui/icons";
import { Link } from "react-router-dom";

function About(props) {
	const ref = useRef();

	return (
		<div className="section-style">
			<motion.h1 className="section-head">
				<span role="img" aria-label="student emoji">
					👨‍🎓
				</span>
				About Me
			</motion.h1>
			<div className="about-content" ref={ref}>
				<Grid container className="about-grid">
					<Grid item sm={12} md={6} className="about-text-div">
						<Fade left>
							<motion.p
								drag={props.interactive}
								dragConstraints={ref}
								className={
									props.interactive
										? "z-index1 grabbable"
										: "z-index1"
								}
							>
								Hello! I am{" "}
								<span className="blue-color">
									Mohammed Alhan
								</span>
								, Computer Science sophomore at {" "}
								<span className="blue-color">
									Vellore Institute of Technology, Vellore
								</span>
								. passionate about innovative technology solutions. Full stack web developer with expertise in backend development and a strong focus on web security. <br /> <br />
								Eager to deep dive into the world of tech! I am good
								at{" "}
								<span className="blue-color">
									Backend Development
								</span>
								, also experienced in{" "}
								<span className="blue-color">
								App development, Ethical hacking, DSA
								</span>
								.
							</motion.p>
							<div className="btn-container">
								<Link
									to="/resume"
									style={{ textDecoration: "none" }}
								>
									<motion.button
										className="cv-btn"
										drag={props.interactive}
										dragConstraints={ref}
									>
										<GetApp className="down-icon" />
										Download CV
									</motion.button>
								</Link>
							</div>
						</Fade>
					</Grid>
					{/* <Grid
						item
						sm={12}
						md={6}
						className={
							props.interactive
								? "profile-img-div grabbable"
								: "profile-img-div"
						}
					>
						<motion.img
							drag={props.interactive}
							dragConstraints={ref}
							src="profile.jpeg"
							alt="my face"
							className={props.interactive ? "grabbable" : null}
						/>
					</Grid> */}
				</Grid>
			</div>
		</div>
	);
}

export default About;
